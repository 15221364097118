import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import process from "process";

import micro_frontend_bmw from "./microfrontend-layout/microfrontend-layout-bmw.html";
import micro_frontend_netsol from "./microfrontend-layout/microfrontend-layout-netsol.html";

console.info("isLocal: ", process.env.isLocal);
console.info("isDev: ", process.env.isDev);
console.info("isStage: ", process.env.isStage);
console.info("isProd: ", process.env.isProd);
console.info("isDemo: ", process.env.isDemo);
console.info("isFeature: ", process.env.isFeature);
console.info("isQa: ", process.env.isQa);
console.info("isAppex: ", process.env.isAppex);
console.info("isBmwDev: ", process.env.isBmwDev);
console.info("isBmwStg: ", process.env.isBmwStg);
console.info("isBmwPreprod: ", process.env.isBmwPreprod);

const loadMicroFrontend = () => {
  if ((process.env.isDev && process.env.isBmwDev) || process.env.isBmwStg || process.env.isBmwPreprod) {
    return micro_frontend_bmw;
  } else if (
    process.env.isLocal ||
    process.env.isDev ||
    process.env.isStage ||
    process.env.isProd ||
    process.env.isDemo ||
    process.env.isFeature ||
    process.env.isQa ||
    process.env.isAppex
  ) {
    return micro_frontend_netsol;
  }
};

const routes = constructRoutes(loadMicroFrontend());
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
